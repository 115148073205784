/*eslint no-unused-expressions: 0 */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};

export const shouldUpdateScroll = ({
                                     routerProps: {location},
                                     prevRouterProps,
                                     getSavedScrollPosition,
                                   }) => {
  const {pathname} = location
  let previousPathname = prevRouterProps?.location?.pathname

  if (pathname.search(/\/(nl|fr)\/showcases\/./) === 0 && (previousPathname || '').search(/\/(nl|fr)\/showcases\/./) === 0) {
    //prevent scroll
    return false;
  }

  return pathname.search(/\/(nl|fr)\/home\/./) !== 0;
}
