// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-component-library-js": () => import("./../../../src/pages/component-library.js" /* webpackChunkName: "component---src-pages-component-library-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-quiz-js": () => import("./../../../src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */),
  "component---src-templates-showcase-detail-js": () => import("./../../../src/templates/showcase-detail.js" /* webpackChunkName: "component---src-templates-showcase-detail-js" */),
  "component---src-templates-showcases-js": () => import("./../../../src/templates/showcases.js" /* webpackChunkName: "component---src-templates-showcases-js" */)
}

